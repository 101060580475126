import logo from './logo.svg';
import './App.css';

function App() {

  
  const linkStyle = {
    color: 'pink',      // Sets the text color to pink
    textDecoration: 'none'  // Removes the underline
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <h1>Under Construction</h1>
          <p>We are currently working on this page.</p>
          <p>Please check back later!</p>
          <p>or check our Instagram page</p>
          <h1>
            <a  href="https://instagram.com/gimnastickoudruzenje11?igshid=MzRlODBiNWFlZAh"  style={linkStyle}>SGU11</a>
          </h1>
        </p>
      </header>
    </div>
  );
}

export default App;
